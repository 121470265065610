import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import Pedido from "./Pedido";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { CRMContext } from "../../context/CRMContext";
const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [auth, guardarAuth] = useContext(CRMContext);
  const consultarApi = async () => {
    if (auth.token !== "") {
      try {
        await clienteAxios
          .get("/pedidos", {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => setPedidos(response.data));
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    if (refresh) {
      consultarApi();
      setRefresh(false);
    }
  }, [refresh]);
  return (
    <>
      <h2>Pedidos</h2>
      <Link to="/pedidos/nuevo-pedidos" className="btn btn-verde nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        Nuevo Pedido
      </Link>
      <ul className="listado-productos">
        {pedidos.length > 0 ? (
          pedidos.map((pedido) => (
            <Pedido key={pedido._id} pedido={pedido} setRefresh={setRefresh} />
          ))
        ) : (
          <Spinner />
        )}
      </ul>
    </>
  );
};

export default Pedidos;
