import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import Cliente from "./Cliente";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { CRMContext } from "../../context/CRMContext";
const Clientes = () => {
  const navigate = useNavigate();
  const [clientes, setClientes] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [auth, guardarAuth] = useContext(CRMContext);
  const consultarApi = async () => {
    if (auth.token !== "") {
      try {
        await clienteAxios
          .get("/clientes", {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => setClientes(response.data));
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    if (refresh) {
      consultarApi();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <h2>Clientes</h2>
      <Link to="/clientes/nuevo-cliente" class="btn btn-success nvo-cliente">
        <i class="fas fa-plus-circle"></i>
        Nuevo Cliente
      </Link>
      <ul className="listado-clientes">
        {clientes.length > 0 &&
          clientes.map((cliente) => (
            <Cliente
              key={cliente._id}
              cliente={cliente}
              setRefresh={setRefresh}
            />
          ))}
      </ul>
    </>
  );
};

export default Clientes;
