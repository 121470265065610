import React, { useEffect, useState } from "react";
import clienteAxios from "../../config/axios";
import Producto from "./Producto";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";

const Productos = () => {
  const [productos, setProductos] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const consultarApi = async () => {
    await clienteAxios
      .get("/productos")
      .then((response) => setProductos(response.data));
  };
  useEffect(() => {
    if (refresh) {
      consultarApi();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <h2>Productos</h2>
      <Link
        to="/productos/nuevo-productos"
        className="btn btn-verde nvo-cliente"
      >
        <i className="fas fa-plus-circle"></i>
        Nuevo Producto
      </Link>
      <ul className="listado-productos">
        {productos.length > 0 ? (
          productos.map((producto) => (
            <Producto
              key={producto._id}
              producto={producto}
              setRefresh={setRefresh}
            />
          ))
        ) : (
          <Spinner />
        )}
      </ul>
    </>
  );
};

export default Productos;
