import React, { useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { CRMContext } from "../../context/CRMContext";
const NuevoCliente = () => {
  const navigate = useNavigate();
  const [auth, guardarAuth] = useContext(CRMContext);
  const [cliente, setCliente] = useState({
    nombre: "",
    apellido: "",
    empresa: "",
    email: "",
    telefono: "",
  });

  const handleOnChange = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };
  const validarCliente = () => {
    const { nombre, apellido, empresa, email, telefono } = cliente;
    let valido =
      !nombre.length ||
      !apellido.length ||
      !empresa.length ||
      !email.length ||
      !telefono.length;
    return valido;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (auth.token !== "") {
      await clienteAxios
        .post("/clientes", cliente, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              title: "Error!",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire({
              title: response.data.mensaje,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            setCliente({
              nombre: "",
              apellido: "",
              empresa: "",
              email: "",
              telefono: "",
            });
            navigate("clientes");
          }
        });
    }
  };
  return (
    <>
      <h2>Nuevo Cliente</h2>

      <form>
        <legend>Llena todos los campos</legend>

        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre Cliente"
            name="nombre"
            value={cliente.nombre}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Apellido:</label>
          <input
            type="text"
            placeholder="Apellido Cliente"
            name="apellido"
            value={cliente.apellido}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Empresa:</label>
          <input
            type="text"
            placeholder="Empresa Cliente"
            name="empresa"
            value={cliente.empresa}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Email Cliente"
            name="email"
            value={cliente.email}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Teléfono:</label>
          <input
            type="tel"
            placeholder="Teléfono Cliente"
            name="telefono"
            value={cliente.telefono}
            onChange={handleOnChange}
          />
        </div>

        <div className="enviar">
          <button
            type="submit"
            class="btn btn-azul"
            onClick={handleSubmit}
            disabled={validarCliente()}
          >
            Agregar Cliente
          </button>
        </div>
      </form>
    </>
  );
};

export default NuevoCliente;
