import React, { useContext, useEffect } from "react";
//Routing
import { Link } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import { useNavigate } from "react-router-dom";

const Navegacion = () => {
  const navigate = useNavigate();
  const [auth, guardarAuth] = useContext(CRMContext);

  useEffect(() => {
    if (!auth.auth && localStorage.getItem("token") != auth.token) {
      navigate("/");
    }
  }, [auth]);

  if (!auth.auth) return null;
  console.log("auth", auth);
  return (
    <aside className="sidebar col-3">
      <h2>Administración</h2>
      <h3>Hola {auth?.user?.nombre}</h3>

      <nav className="navegacion">
        {auth?.user?.rol == "2" ? (
          <Link to={"/facturacion"} className="facturacion">
            Facturación
          </Link>
        ) : (
          <>
            <Link to={"/clientes"} className="clientes">
              Clientes
            </Link>
            <Link to={"/productos"} className="productos">
              Productos
            </Link>
            <Link to={"/pedidos"} className="pedidos">
              Pedidos
            </Link>
            <Link to={"/facturacion"} className="facturacion">
              Facturación
            </Link>
          </>
        )}
      </nav>
    </aside>
  );
};

export default Navegacion;
