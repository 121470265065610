import React, { useState, useEffect } from "react";
import clienteAxios from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const EditarProducto = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [producto, setProducto] = useState({
    nombre: "",
    precio: 0,
    imagen: "",
  });
  const [archivo, setArchivo] = useState("");

  const getData = async () => {
    await clienteAxios.get(`/productos/${id}`).then((response) => {
      setProducto({
        nombre: response.data.nombre,
        precio: response.data.precio,
        imagen: response.data.imagen,
      });
    });
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleOnChange = (e) => {
    setProducto({
      ...producto,
      [e.target.name]: e.target.value,
    });
  };
  const leerArchivo = (e) => {
    setArchivo(e.target.files[0]);
  };

  const validarProducto = () => {
    const { nombre, precio } = producto;
    let valido = !nombre.length || precio === 0;

    return valido;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //crear un form-data
    const formData = new FormData();
    formData.append("nombre", producto.nombre);
    formData.append("precio", producto.precio);
    if (archivo != "") {
      formData.append("imagen", archivo);
    } else {
      formData.append("imagen", producto.imagen);
    }
    try {
      await clienteAxios
        .put(`/productos/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              title: "Error!",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire({
              title: response.data.mensaje,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            setProducto({
              nombre: "",
              precio: "",
            });
            setArchivo("");
            navigate("/productos");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <h2>Editar Producto</h2>
      <form>
        <legend>Llena todos los campos</legend>

        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre Producto"
            name="nombre"
            value={producto.nombre}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Precio:</label>
          <input
            type="number"
            name="precio"
            min="0.00"
            step="0.01"
            placeholder="Precio"
            value={producto.precio}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Imagen:</label>

          <input type="file" name="imagen" onChange={leerArchivo} />
        </div>
        {producto.imagen && (
          <img
            src={`https://api.bladelink.company/uploads/${producto.imagen}`}
            alt={producto.nombre}
            style={{ height: "200px", width: "auto" }}
          />
        )}

        <div className="enviar">
          <button
            type="submit"
            className="btn btn-azul"
            onClick={handleSubmit}
            disabled={validarProducto()}
          >
            Editar Producto
          </button>
        </div>
      </form>
    </>
  );
};

export default EditarProducto;
