import React, { useState } from "react";
import clienteAxios from "../../config/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const NuevoProducto = () => {
  const navigate = useNavigate();
  const [producto, setProducto] = useState({
    nombre: "",
    precio: "",
  });
  const [archivo, setArchivo] = useState("");

  const handleOnChange = (e) => {
    setProducto({
      ...producto,
      [e.target.name]: e.target.value,
    });
  };
  const leerArchivo = (e) => {
    setArchivo(e.target.files[0]);
  };

  const validarProducto = () => {
    const { nombre, precio } = producto;
    let valido = !nombre.length || !precio.length;
    return valido;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    //crear un form-data
    const formData = new FormData();
    formData.append("nombre", producto.nombre);
    formData.append("precio", producto.precio);
    formData.append("imagen", archivo);
    try {
      await clienteAxios
        .post("/productos", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              title: "Error!",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire({
              title: response.data.mensaje,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });
            setProducto({
              nombre: "",
              precio: "",
            });
            setArchivo("");
            navigate("/productos");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <h2>Nuevo Producto</h2>
      <form>
        <legend>Llena todos los campos</legend>

        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre Producto"
            name="nombre"
            value={producto.nombre}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Precio:</label>
          <input
            type="number"
            name="precio"
            min="0.00"
            step="0.01"
            placeholder="Precio"
            value={producto.precio}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Imagen:</label>
          <input type="file" name="imagen" onChange={leerArchivo} />
        </div>

        <div className="enviar">
          <button
            type="submit"
            className="btn btn-azul"
            onClick={handleSubmit}
            disabled={validarProducto()}
          >
            Agregar Producto
          </button>
        </div>
      </form>
    </>
  );
};

export default NuevoProducto;
