import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";

const Producto = ({ producto, setRefresh }) => {
  const { imagen } = producto;

  const handleDelete = (id) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podras revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, borrar producto!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await clienteAxios
          .delete(`productos/${id}`)
          .then((response) => {
            Swal.fire("Borrado!", response.data, "success");
            setRefresh(true);
          })
          .catch((error) => {
            Swal.fire("Error!", error, "error");
          });
      }
    });
  };
  return (
    <>
      <li className="producto">
        <div className="info-producto">
          <p className="nombre">{producto.nombre}</p>
          <p className="precio">${producto.precio}</p>
          {imagen && (
            <img
              src={`https://api.bladelink.company/uploads/${producto.imagen}`}
              alt={producto.nombre}
              style={{ width: "auto", height: "200px" }}
            />
          )}
        </div>
        <div className="acciones">
          <Link
            to={`/productos/editar-productos/${producto._id}`}
            className="btn btn-azul"
          >
            <i className="fas fa-pen-alt"></i>
            Editar Producto
          </Link>

          <button
            type="button"
            className="btn btn-rojo btn-eliminar"
            onClick={() => handleDelete(producto._id)}
          >
            <i className="fas fa-times"></i>
            Eliminar Producto
          </button>
        </div>
      </li>
    </>
  );
};

export default Producto;
