import React, { useState, useEffect, useContext } from "react";
import clienteAxios from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import FormBuscarProductos from "./FormBuscarProductos";
import FormCantidadProducto from "./FormCantidadProducto";
import { CRMContext } from "../../context/CRMContext";
const NuevoPedido = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [auth, guardarAuth] = useContext(CRMContext);
  const [cliente, setCliente] = useState({});
  const [busqueda, setBusqueda] = useState("");
  const [productos, setProductos] = useState([]);

  const getData = async () => {
    if (auth.token !== "") {
      try {
        await clienteAxios
          .get(`/clientes/${id}`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => {
            setCliente(response.data);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const buscarProducto = async (e) => {
    e.preventDefault();
    //obtener los productos de la busqueda
    if (auth.token !== "") {
      try {
        await clienteAxios
          .post(`/productos/busqueda/${busqueda}`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => {
            if (response.data[0]) {
              let productoResultado = response.data[0];
              productoResultado.producto = response.data[0]._id;
              productoResultado.cantidad = 0;
              setProductos([...productos, productoResultado]);
            } else {
              //no hay resultados
              Swal.fire({
                title: "No hay resultados!",
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const leerDatosBusqueda = (e) => {
    setBusqueda(e.target.value);
  };

  return (
    <>
      <h2>Nuevo Pedido</h2>
      <div className="ficha-cliente">
        <h3>Datos de Cliente</h3>
        <p>
          {cliente.nombre} {cliente.apellido}
        </p>
        <p>{cliente.email}</p>
        <p>{cliente.telefono}</p>
      </div>

      <FormBuscarProductos
        buscarProducto={buscarProducto}
        leerDatosBusqueda={leerDatosBusqueda}
        busqueda={busqueda}
      />

      <ul className="resumen">
        {productos.length > 0 &&
          productos.map((producto) => (
            <FormCantidadProducto producto={producto} key={producto._id} />
          ))}
      </ul>
      <div className="campo">
        <label>Total:</label>
        <input
          type="number"
          name="precio"
          placeholder="Precio"
          readonly="readonly"
        />
      </div>
    </>
  );
};

export default NuevoPedido;
