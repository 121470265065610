import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { useNavigate } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import { Table, Container, Card, Accordion } from "react-bootstrap";
import { format, compareAsc } from "date-fns";
import NumberFormat from "react-number-format";
import ReporteCompras from "./ReporteCompras";
const Facturas = () => {
  const navigate = useNavigate();
  const [facturas, setFacturas] = useState([]);
  const [compras, setCompras] = useState([]);

  const [refresh, setRefresh] = useState(true);
  const [auth, guardarAuth] = useContext(CRMContext);
  const consultarApi = async () => {
    if (auth.token !== "") {
      try {
        await clienteAxios
          .get("/facturas", {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => setFacturas(response.data));

        await clienteAxios
          .get("/compras", {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => setCompras(response.data));
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    if (refresh) {
      consultarApi();
      setRefresh(false);
    }
  }, [refresh]);

  const formatFecha = (date) => {
    let fecha = new Date(date);
    fecha.setDate(fecha.getDate() + 1);
    return format(fecha, "dd/MM/yyyy");
  };

  const calculateTotal = () => {
    let positivo = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((item) => item.total)
      .reduce((prev, curr) => prev + curr, 0);
    let negativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((item) => item.total)
      .reduce((prev, curr) => prev + curr, 0);
    return positivo - negativo;
  };
  const calculateTotalNeta = () => {
    let positivo = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((item) => item.neto)
      .reduce((prev, curr) => prev + curr, 0);

    let negativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((item) => item.neto)
      .reduce((prev, curr) => prev + curr, 0);
    return positivo - negativo;
  };
  const calculateTotalIVA = () => {
    let fas = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((i) => i.iva);

    const iva21 = fas
      .map((item) => item.iva21)
      .reduce((prev, curr) => prev + curr, 0);
    const iva27 = fas
      .map((item) => item.iva27)
      .reduce((prev, curr) => prev + curr, 0);
    const iva105 = fas
      .map((item) => item.iva105)
      .reduce((prev, curr) => prev + curr, 0);

    let fasnegativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((i) => i.iva);

    const iva21neg = fasnegativo
      .map((item) => item.iva21)
      .reduce((prev, curr) => prev + curr, 0);
    const iva27neg = fasnegativo
      .map((item) => item.iva27)
      .reduce((prev, curr) => prev + curr, 0);
    const iva105neg = fasnegativo
      .map((item) => item.iva105)
      .reduce((prev, curr) => prev + curr, 0);

    return iva21 + iva27 + iva105 - iva21neg - iva27neg - iva105neg;
  };
  return (
    <>
      <h2>Facturación</h2>
      <Link to="/facturacion/nueva-venta" className="btn btn-verde nvo-cliente">
        <i className="fas fa-plus-circle"></i>
        Nueva Venta
      </Link>
      <Link
        to="/facturacion/nueva-compra"
        className="ml-2 btn btn-rojo nvo-cliente"
      >
        <i className="fas fa-plus-circle"></i>
        Nueva Compra
      </Link>
      <Container className="mt-5">
        <Card>
          <Card.Header>Reporte Ventas</Card.Header>
          <Card.Body className="grid grid-cols-3">
            <div>
              <h5>Total de Ventas BRUTAS: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotal()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
            <div>
              <h5>Total de Ventas NETAS: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotalNeta()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
            <div>
              <h5>Total de IVA VENTAS: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotalIVA()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
          </Card.Body>
        </Card>
      </Container>

      <ReporteCompras />

      <Container className="mt-5">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Ventas</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Cuit</th>
                    <th>Total</th>
                    <th>Neto</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {facturas.length > 0 &&
                    facturas.map((f) => (
                      <tr key={f._id}>
                        <td>{f.type}</td>
                        <td>{formatFecha(f.date)}</td>
                        <td>
                          <NumberFormat
                            displayType={"text"}
                            value={f.cuit}
                            format="##-########-#"
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={f.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={f.neto}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <div className="d-flex justify-content-start gap-3">
                            <Link to="/facturacion/nueva-venta" className="pdf">
                              <a
                                href={`https://api.bladelink.company/uploads/${f.imagen}`}
                              >
                                <div className="pdf"></div>
                              </a>
                            </Link>
                            <Link
                              to={`/facturacion/editar-venta/${f._id}`}
                              className="edit"
                            ></Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Compras</Accordion.Header>
            <Accordion.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Fecha</th>
                    <th>Cuit</th>
                    <th>Total</th>
                    <th>Neto</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {compras.length > 0 &&
                    compras.map((f) => (
                      <tr key={f._id}>
                        <td>{f.type}</td>
                        <td>{formatFecha(f.date)}</td>
                        <td>
                          <NumberFormat
                            displayType={"text"}
                            value={f.cuit}
                            format="##-########-#"
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={f.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <NumberFormat
                            value={f.neto}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </td>
                        <td>
                          <div className="d-flex justify-content-start gap-3">
                            <Link to="/facturacion/nueva-venta" className="pdf">
                              <a
                                href={`https://api.bladelink.company/uploads/${f.imagen}`}
                              >
                                <div className="pdf"></div>
                              </a>
                            </Link>
                            <Link
                              to={`/facturacion/editar-compra/${f._id}`}
                              className="edit"
                            ></Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </>
  );
};

export default Facturas;
