import React, { useState, useEffect, useContext } from "react";
import clienteAxios from "../../config/axios";

import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";

import { Container, Button } from "react-bootstrap";
const Login = () => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const navigate = useNavigate();
  const [credenciales, setCredenciales] = useState({
    email: "",
    password: "",
  });
  const leerDato = (e) => {
    setCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await clienteAxios
        .post("/iniciar-sesion", credenciales)
        .then((response) => {
          const { token, usuario } = response.data;
          localStorage.setItem("token", token);
          // colocarlo en el state
          guardarAuth({ token, auth: true, user: usuario });
          Swal.fire("Login Correcto", "Has iniciado sesión", "success");
          if (usuario.rol === "2") {
            navigate("/facturacion");
          } else {
            navigate("/clientes");
          }
        });
    } catch (error) {
      console.log(error);
      guardarAuth({ token: "", auth: false });
      Swal.fire({
        title: "Error!",
        icon: "error",
        text: error.response.data.mensaje,
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };
  return (
    <Container>
      <div className="login">
        <h2>Iniciar sesión</h2>
        <div className="contenedor-fomulario">
          <form onSubmit={handleSubmit}>
            <div className="campo">
              <label>Email</label>
              <input
                type="text"
                name="email"
                placeholder="Email para iniciar sesión"
                required
                onChange={leerDato}
              />
            </div>
            <div className="campo">
              <label>Contraseña</label>
              <input
                type="password"
                name="password"
                placeholder="Contraseña para iniciar sesión"
                required
                onChange={leerDato}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Button type="submit">Iniciar sesión </Button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default Login;
