import React from "react";

const FormBuscarProductos = (props) => {
  return (
    <>
      <form onSubmit={props.buscarProducto}>
        <legend>Busca un Producto y agrega una cantidad</legend>

        <div className="campo">
          <label>Productos:</label>
          <input
            type="text"
            placeholder="Nombre Productos"
            name="busqueda"
            onChange={props.leerDatosBusqueda}
            value={props.busqueda}
          />
        </div>

        <div>
          <button type="submit" className="btn btn-azul btn-block">
            Buscar Producto
          </button>
        </div>
      </form>
    </>
  );
};

export default FormBuscarProductos;
