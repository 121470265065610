import React, { useEffect, useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { Link } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { useNavigate } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
import { Table, Container, Card } from "react-bootstrap";
import { format, compareAsc } from "date-fns";
import NumberFormat from "react-number-format";

const ReporteCompras = () => {
  const navigate = useNavigate();
  const [facturas, setFacturas] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [auth, guardarAuth] = useContext(CRMContext);

  const consultarApi = async () => {
    if (auth.token !== "") {
      try {
        await clienteAxios
          .get("/compras", {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => setFacturas(response.data));
      } catch (error) {
        console.log(error);
        navigate("/");
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    if (refresh) {
      consultarApi();
      setRefresh(false);
    }
  }, [refresh]);

  const formatFecha = (date) => {
    let fecha = new Date(date);
    fecha.setDate(fecha.getDate() + 1);
    return format(fecha, "dd/MM/yyyy");
  };

  const calculateTotal = () => {
    let positivo = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((item) => item.total)
      .reduce((prev, curr) => prev + curr, 0);
    let negativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((item) => item.total)
      .reduce((prev, curr) => prev + curr, 0);
    return positivo - negativo;
  };
  const calculateTotalNeta = () => {
    let positivo = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((item) => item.neto)
      .reduce((prev, curr) => prev + curr, 0);

    let negativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((item) => item.neto)
      .reduce((prev, curr) => prev + curr, 0);
    return positivo - negativo;
  };
  const calculateTotalIVA = () => {
    let fas = facturas
      .filter((f) => f.category === false && (f.type === 1 || f.type === 2))
      .map((i) => i.iva);

    const iva21 = fas
      .map((item) => item.iva21)
      .reduce((prev, curr) => prev + curr, 0);
    const iva27 = fas
      .map((item) => item.iva27)
      .reduce((prev, curr) => prev + curr, 0);
    const iva105 = fas
      .map((item) => item.iva105)
      .reduce((prev, curr) => prev + curr, 0);

    let fasnegativo = facturas
      .filter((f) => f.category === false && (f.type === 3 || f.type === 4))
      .map((i) => i.iva);

    const iva21neg = fasnegativo
      .map((item) => item.iva21)
      .reduce((prev, curr) => prev + curr, 0);
    const iva27neg = fasnegativo
      .map((item) => item.iva27)
      .reduce((prev, curr) => prev + curr, 0);
    const iva105neg = fasnegativo
      .map((item) => item.iva105)
      .reduce((prev, curr) => prev + curr, 0);

    return iva21 + iva27 + iva105 - iva21neg - iva27neg - iva105neg;
  };

  return (
    <>
      <Container className="mt-5">
        <Card>
          <Card.Header>Reporte Compras</Card.Header>
          <Card.Body className="grid grid-cols-3">
            <div>
              <h5>Total de Compras BRUTAS: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotal()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
            <div>
              <h5>Total de Compras NETAS: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotalNeta()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
            <div>
              <h5>Total de IVA COMPRA: </h5>
              <NumberFormat
                displayType={"text"}
                value={calculateTotalIVA()}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
              />
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default ReporteCompras;
