import React, { useState, useContext } from "react";
import clienteAxios from "../../config/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import { CRMContext } from "../../context/CRMContext";
const NuevaVenta = () => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const navigate = useNavigate();
  const [otros, setOtros] = useState({
    nombre: "",
    impuesto: 0,
  });
  const [archivo, setArchivo] = useState("");
  const leerArchivo = (e) => {
    setArchivo(e.target.files[0]);
  };
  const [factura, setFactura] = useState({
    number: "",
    cuit: "",
    clientId: "",
    tipo: 1,
    date: new Date(),
    neto: 0,
    total: 0,
    iva: {
      iva21: 0,
      iva105: 0,
      iva27: 0,
    },
    otros: [],
  });

  const handleOnChange = (e) => {
    if (e.target.name == "total") {
      setFactura({
        ...factura,
        neto: (e.target.value / 1.21).toFixed(2),
        iva: {
          ...factura.iva,
          iva21: (e.target.value - e.target.value / 1.21).toFixed(2),
        },
        [e.target.name]: parseFloat(e.target.value),
      });
      return;
    }
    if (e.target.name == "iva105") {
      let neto = (
        factura.total -
        (factura.iva.iva21 + parseFloat(e.target.value) + factura.iva.iva27)
      ).toFixed(2);
      console.log("neto", factura.total, factura.iva, neto);
      setFactura({
        ...factura,

        neto: neto,
        iva: {
          ...factura.iva,
          [e.target.name]: parseFloat(e.target.value),
        },
      });
      return;
    }
    if (e.target.name == "iva21") {
      let neto = (
        factura.total -
        (factura.iva.iva27 + parseFloat(e.target.value) + factura.iva.iva105)
      ).toFixed(2);
      setFactura({
        ...factura,

        neto: neto,
        iva: {
          ...factura.iva,
          [e.target.name]: parseFloat(e.target.value),
        },
      });
      return;
    }
    if (e.target.name == "iva27") {
      let neto = (
        factura.total -
        (factura.iva.iva21 + parseFloat(e.target.value) + factura.iva.iva105)
      ).toFixed(2);
      setFactura({
        ...factura,

        neto: neto,
        iva: {
          ...factura.iva,
          [e.target.name]: parseFloat(e.target.value),
        },
      });
      return;
    }

    setFactura({
      ...factura,
      [e.target.name]: e.target.value,
    });
  };
  const handleOtrosChange = (e) => {
    setOtros({
      ...otros,
      [e.target.name]: e.target.value,
    });
  };
  const addOtros = () => {
    let aux = factura.otros;
    let otro = otros;
    otro.id = uuid();
    aux.push(otro);
    setOtros({
      nombre: "",
      impuesto: 0,
      id: uuid(),
    });
    setFactura({ ...factura, otros: aux });
  };
  const eliminarOtro = (id) => {
    let aux = factura.otros.filter((i) => i.id != id);
    setFactura({ ...factura, otros: aux });
  };
  const validarProducto = () => {
    const { iva, total, neto, number, date } = factura;
    const { iva21, iva105, iva27 } = iva;

    let valido =
      (total - neto - iva21 - iva105 - iva27).toFixed(2) == 0.0
        ? false
        : true || !number || !date;

    return valido;
  };
  function createFormData(formData, key, data) {
    if (data === Object(data) || Array.isArray(data)) {
      for (var i in data) {
        createFormData(formData, key + "[" + i + "]", data[i]);
      }
    } else {
      formData.append(key, data);
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    //crear un form-data
    const formData = new FormData();
    formData.append("type", factura.tipo);
    formData.append("category", 0);
    formData.append("number", factura.number);
    formData.append("date", factura.date);
    formData.append("cuit", factura.cuit);
    formData.append("total", factura.total);
    formData.append("neto", factura.neto);
    createFormData(formData, "otros", factura.otros);

    formData.append("iva[iva21]", factura.iva.iva21);
    formData.append("iva[iva27]", factura.iva.iva27);
    formData.append("iva[iva105]", factura.iva.iva105);

    formData.append("imagen", archivo);
    console.log("arhivo", archivo);
    try {
      await clienteAxios
        .post("/facturas", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              title: "Error!",
              icon: "error",
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            Swal.fire({
              title: response.data.mensaje,
              icon: "success",
              showConfirmButton: false,
              timer: 1000,
            });

            navigate("/facturacion");
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h1>Nueva venta</h1>
      <select value={factura.tipo} name="tipo" onChange={handleOnChange}>
        <option value={1}>Factura A</option>
        <option value={2}>Factura B</option>
        <option value={3}>Nota de crédito A</option>
        <option value={4}>Nota de crédito B</option>
        <option value={5}>Nota de debito A</option>
        <option value={6}>Nota de debito B</option>
      </select>
      <form>
        <div className="campo">
          <label>Número:</label>
          <input
            type="text"
            name="number"
            placeholder="Número de factura"
            value={factura.number}
            onChange={handleOnChange}
          />
        </div>
        <div className="campo">
          <label>Fecha:</label>
          <input
            type="date"
            name="date"
            placeholder="Fecha de factura"
            value={factura.date}
            onChange={handleOnChange}
          />
        </div>
        <div className="campo">
          <label>Cuit:</label>
          <input
            type="text"
            placeholder="CUIT"
            name="cuit"
            value={factura.cuit}
            onChange={handleOnChange}
          />
        </div>
        <div className="campo-group">
          <div className="camponumber">
            <label>Total:</label>
            <input
              type="number"
              name="total"
              min="0.00"
              step="0.01"
              placeholder="Importe Total"
              value={factura.total}
              onChange={handleOnChange}
            />
          </div>
          <div className="camponumber">
            <label>Neto:</label>
            <input
              type="number"
              name="neto"
              min="0.00"
              step="0.01"
              placeholder="Importe Neto"
              value={factura.neto}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="campo-group">
          <div className="camponumber">
            <label>10.5%:</label>
            <input
              type="number"
              name="iva105"
              min="0.00"
              step="0.01"
              value={factura.iva.iva105}
              onChange={handleOnChange}
            />
          </div>
          <div className="camponumber">
            <label>21%:</label>
            <input
              type="number"
              name="iva21"
              min="0.00"
              step="0.01"
              value={factura.iva.iva21}
              onChange={handleOnChange}
            />
          </div>
          <div className="camponumber">
            <label>27%:</label>
            <input
              type="number"
              name="iva27"
              min="0.00"
              step="0.01"
              value={factura.iva.iva27}
              onChange={handleOnChange}
            />
          </div>
        </div>
        <div className="campo-group">
          <div className="camponumber">
            <label>Otros:</label>
            <input
              type="text"
              name="nombre"
              placeholder="nombre"
              value={otros.nombre}
              onChange={handleOtrosChange}
            />
          </div>
          <div className="camponumber">
            <label>Importe:</label>
            <input
              type="number"
              name="impuesto"
              min="0.00"
              placeholder="Importe Otros"
              value={otros.impuesto}
              onChange={handleOtrosChange}
            />
          </div>

          <button type="button" onClick={() => addOtros()}>
            Agregar
          </button>
        </div>
        <div>
          <ul>
            {factura.otros.map((otro) => (
              <li key={otro.id}>
                {otro.nombre} : {otro.impuesto}{" "}
                <button tyoe="button" onClick={() => eliminarOtro(otro.id)}>
                  eliminar
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="campo">
          <label>Imagen:</label>
          <input type="file" name="imagen" onChange={leerArchivo} />
        </div>

        <div className="enviar">
          <button
            type="submit"
            className="btn btn-azul"
            onClick={handleSubmit}
            disabled={validarProducto()}
          >
            Agregar Venta
          </button>
        </div>
      </form>
    </>
  );
};

export default NuevaVenta;
