import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
const Cliente = ({ cliente, setRefresh }) => {
  const [auth, guardarAuth] = useContext(CRMContext);
  const handleDelete = (id) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podras revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, borrar cliente!",
    }).then(async (result) => {
      if (result.isConfirmed && auth.token !== "") {
        await clienteAxios
          .delete(`clientes/${id}`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => {
            Swal.fire("Borrado!", response.data, "success");
            setRefresh(true);
          });
      }
    });
  };
  return (
    <li className="cliente">
      <div className="info-cliente">
        <p className="nombre">
          {cliente.nombre} {cliente.apellido}
        </p>
        <p className="empresa">{cliente.empresa}</p>
        <p>{cliente.email}</p>
        <p>{cliente.telefono}</p>
      </div>
      <div className="acciones">
        <Link
          to={`/clientes/editar-cliente/${cliente._id}`}
          className="btn btn-azul"
        >
          <i className="fas fa-pen-alt"></i>
          Editar Cliente
        </Link>
        <button
          type="button"
          className="btn btn-rojo btn-eliminar"
          onClick={() => handleDelete(cliente._id)}
        >
          <i className="fas fa-times"></i>
          Eliminar Cliente
        </button>
      </div>
    </li>
  );
};

export default Cliente;
