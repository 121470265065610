import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import clienteAxios from "../../config/axios";
import { CRMContext } from "../../context/CRMContext";
const Pedido = ({ pedido, setRefresh }) => {
  const [auth, guardarAuth] = useContext(CRMContext);

  const handleDelete = (id) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podras revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, borrar pedido!",
    }).then(async (result) => {
      if (result.isConfirmed && auth.token !== "") {
        await clienteAxios
          .delete(`pedidos/${id}`, {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          })
          .then((response) => {
            Swal.fire("Borrado!", response.data, "success");
            setRefresh(true);
          });
      }
    });
  };

  return (
    <>
      <li className="pedido">
        <div className="info-pedido">
          <p className="id">ID: {pedido._id}</p>
          <p className="nombre">Cliente:{pedido.cliente.nombre}</p>

          <div className="articulos-pedido">
            <p className="productos">Artículos Pedido: </p>
            <ul>
              {pedido.pedido.map((item) => (
                <li>
                  <p>{item.producto?.nombre}</p>
                  <p>${item.producto?.precio}</p>
                  <p>Cantidad: {item.cantidad}</p>
                </li>
              ))}
            </ul>
          </div>
          <p className="total">Total: ${pedido.total} </p>
        </div>
        <div className="acciones">
          <Link
            to={`/clientes/editar-cliente/${pedido.cliente._id}`}
            className="btn btn-azul"
          >
            <i className="fas fa-pen-alt"></i>
            Editar Cliente
          </Link>
          <Link
            to={`/pedidos/nuevo-pedidos/${pedido.cliente._id}`}
            className="btn btn-amarillo"
          >
            <i className="fas fa-plus"></i>
            Nuevo Pedido
          </Link>

          <button
            type="button"
            className="btn btn-rojo btn-eliminar"
            onClick={() => handleDelete(pedido._id)}
          >
            <i className="fas fa-times"></i>
            Eliminar Pedido
          </button>
        </div>
      </li>
    </>
  );
};

export default Pedido;
