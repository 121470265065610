import React, { useState, useEffect, useContext } from "react";
import clienteAxios from "../../config/axios";

import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { CRMContext } from "../../context/CRMContext";
const EditarCliente = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [cliente, setCliente] = useState({
    nombre: "",
    apellido: "",
    empresa: "",
    email: "",
    telefono: "",
  });
  const [auth, guardarAuth] = useContext(CRMContext);
  const getData = async () => {
    if (auth.token !== "") {
      await clienteAxios
        .get(`/clientes/${id}`, {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        })
        .then((response) => {
          setCliente(response.data);
        });
    }
  };

  useEffect(() => {
    getData();
  }, [id]);

  const handleOnChange = (e) => {
    setCliente({
      ...cliente,
      [e.target.name]: e.target.value,
    });
  };
  const validarCliente = () => {
    const { nombre, apellido, empresa, email, telefono } = cliente;
    let valido =
      !nombre.length ||
      !apellido.length ||
      !empresa.length ||
      !email.length ||
      !telefono.length;
    return valido;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await clienteAxios
      .put(`/clientes/${cliente._id}`, cliente, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
      })
      .then((response) => {
        if (response.data.error) {
          Swal.fire({
            title: "Error!",
            icon: "error",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          Swal.fire({
            title: "Cliente editado!",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          });
          setCliente({
            nombre: "",
            apellido: "",
            empresa: "",
            email: "",
            telefono: "",
          });
          navigate("/clientes");
        }
      });
  };
  return (
    <>
      <h2>Editar Cliente</h2>

      <form>
        <legend>Llena todos los campos</legend>

        <div className="campo">
          <label>Nombre:</label>
          <input
            type="text"
            placeholder="Nombre Cliente"
            name="nombre"
            value={cliente.nombre}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Apellido:</label>
          <input
            type="text"
            placeholder="Apellido Cliente"
            name="apellido"
            value={cliente.apellido}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Empresa:</label>
          <input
            type="text"
            placeholder="Empresa Cliente"
            name="empresa"
            value={cliente.empresa}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Email:</label>
          <input
            type="email"
            placeholder="Email Cliente"
            name="email"
            value={cliente.email}
            onChange={handleOnChange}
          />
        </div>

        <div className="campo">
          <label>Teléfono:</label>
          <input
            type="tel"
            placeholder="Teléfono Cliente"
            name="telefono"
            value={cliente.telefono}
            onChange={handleOnChange}
          />
        </div>

        <div className="enviar">
          <button
            type="submit"
            class="btn btn-azul"
            onClick={handleSubmit}
            disabled={validarCliente()}
          >
            Editar Cliente
          </button>
        </div>
      </form>
    </>
  );
};

export default EditarCliente;
