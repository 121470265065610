import React, { useContext } from "react";
import Header from "./components/layout/Header";
import Navegacion from "./components/layout/Navegacion";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import Clientes from "./components/clientes/Clientes";
import Pedidos from "./components/pedidos/Pedidos";
import Productos from "./components/productos/Productos";
import NuevoCliente from "./components/clientes/NuevoCliente";
import EditarCliente from "./components/clientes/EditarCliente";
import NuevoProducto from "./components/productos/NuevoProducto";
import EditarProducto from "./components/productos/EditarProducto";
import NuevoPedido from "./components/pedidos/NuevosPedido";
import Login from "./components/auth/Login";
import { CRMContext, CRMProvider } from "./context/CRMContext";
import Facturas from "./components/facturacion/Facturas";
import NuevaCompra from "./components/facturacion/NuevaCompra";
import NuevaVenta from "./components/facturacion/NuevaVenta";
import "./css/app.css";
import EditarVenta from "./components/facturacion/EditarVenta";
import EditarCompra from "./components/facturacion/EditarCompra";
function App() {
  //utilizr context en el componente
  const [auth, guardarAuth] = useContext(CRMContext);

  return (
    <CRMProvider value={{ auth, guardarAuth }}>
      <BrowserRouter>
        <Header />

        <div className="grid container contenido-principal">
          <Navegacion />
          <main className={auth ? "caja-contenedor col-9" : "col-12"}>
            <Routes>
              <Route path="/" element={<Login />} />
              {auth?.user?.rol == "2" ? (
                <>
                  <Route path="/facturacion" element={<Facturas />} />
                  <Route
                    path="/facturacion/nueva-compra"
                    element={<NuevaCompra />}
                  />
                  <Route
                    path="/facturacion/nueva-venta"
                    element={<NuevaVenta />}
                  />
                  <Route
                    path="/facturacion/editar-venta/:id"
                    element={<EditarVenta />}
                  />
                  <Route
                    path="/facturacion/editar-compra/:id"
                    element={<EditarCompra />}
                  />
                </>
              ) : (
                <>
                  <Route path="/clientes" element={<Clientes />} />
                  <Route
                    path="/clientes/nuevo-cliente"
                    element={<NuevoCliente />}
                  />
                  <Route
                    path="/clientes/editar-cliente/:id"
                    element={<EditarCliente />}
                  />
                  <Route path="/productos" element={<Productos />} />
                  <Route
                    path="/productos/nuevo-productos"
                    element={<NuevoProducto />}
                  />

                  <Route
                    path="/productos/editar-productos/:id"
                    element={<EditarProducto />}
                  />
                  <Route path="/pedidos" element={<Pedidos />} />
                  <Route
                    path="/pedidos/nuevo-pedidos/"
                    element={<NuevoPedido />}
                  />
                  <Route
                    path="/pedidos/nuevo-pedidos/:id"
                    element={<NuevoPedido />}
                  />
                  <Route path="/facturacion" element={<Facturas />} />
                  <Route
                    path="/facturacion/nueva-compra"
                    element={<NuevaCompra />}
                  />
                  <Route
                    path="/facturacion/nueva-venta"
                    element={<NuevaVenta />}
                  />
                  <Route
                    path="/facturacion/editar-venta/:id"
                    element={<EditarVenta />}
                  />
                  <Route
                    path="/facturacion/editar-compra/:id"
                    element={<EditarCompra />}
                  />
                </>
              )}
            </Routes>
          </main>
        </div>
      </BrowserRouter>
    </CRMProvider>
  );
}

export default App;
